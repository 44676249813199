import Vue from 'vue';
import VueRouter from 'vue-router';
import Container from '@/views/Container.vue';
import Home from '@/views/Home.vue';
import Features from '@/views/Features.vue';
import Performance from '@/views/Performance.vue';
import PerformanceDetail from '@/views/PerformanceDetail.vue';
import About from '@/views/About.vue';
import Register from '@/views/Register.vue';
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Info from '@/views/Info.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Container',
    component: Container,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'features',
        name: 'Features',
        component: Features,
      },
      {
        path: 'performance',
        name: 'Performance',
        component: Performance,
      },
      {
        path: 'performance/:id',
        name: 'PerformanceDetail',
        component: PerformanceDetail,
        props: true,
      },
      {
        path: 'about',
        name: 'About',
        component: About,
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/info/:id',
        name: 'Info',
        component: Info,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
