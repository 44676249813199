<template>
  <div>
    <app-navbar></app-navbar>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppNavbar from '@/components/Navbar.vue';
import AppFooter from '@/components/Footer.vue';

export default {
  components: {
    AppNavbar,
    AppFooter,
  },
};
</script>
