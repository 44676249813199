import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import AOS from 'aos';
import VueSimpleMarkdown from 'vue-simple-markdown';
import VueApollo from 'vue-apollo';
import apolloClient from '@/services/apollo';
import App from './App.vue';
import router from './router';
import './assets/fonts/Feather/feather.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'aos/dist/aos.css';
import 'choices.js/public/assets/styles/choices.min.css';
import 'flickity-fade/flickity-fade.css';
import 'flickity/dist/flickity.min.css';
import 'highlightjs/styles/vs2015.css';
import 'jarallax/dist/jarallax.css';
import 'quill/dist/quill.core.css';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';
import './assets/css/theme.css';
// <link href='https://api.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css' rel='stylesheet' />

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.use(VueSimpleMarkdown);
new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
AOS.init();
