<template>
  <div>
    <section>
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center no-gutters min-vh-100">
          <div class="col-12 col-md-6 col-lg-4 py-8 py-md-11">

            <!-- Heading -->
            <h1 class="mb-0 font-weight-bold">
              Log in
            </h1>

            <!-- Text -->
            <p class="mb-6 text-muted">
              Cut your cloud costs in minutes.
            </p>

            <!-- Form -->
            <form class="mb-6" @submit.prevent="login()">

              <!-- Email -->
              <div class="form-group">
                <label for="email">
                  Email Address
                </label>
                <input type="email" class="form-control" id="email" placeholder="name@address.com" v-model="email">
              </div>

              <!-- Password -->
              <div class="form-group mb-5">
                <label for="password">
                  Password
                </label>
                <input type="password" class="form-control" id="password" placeholder="Enter your password" v-model="password">
              </div>

              <!-- Submit -->
              <button class="btn btn-block btn-primary" type="submit"
                :disabled="!formValid"
                v-if="!loading"
              >
                Log in
              </button>
              <b-spinner label="Loading" v-if="loading"></b-spinner>

            </form>

            <!-- Text -->
            <p class="mb-0 font-size-sm text-muted">
              Need an account? <a href="#/register">Sign up</a>.
            </p>

          </div>
          <div class="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">

            <!-- Image -->
            <div class="h-100 w-cover bg-cover" style="background-image: url(assets/img/covers/cover-15.jpg);"></div>

            <!-- Shape -->
            <div class="shape shape-left shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"/></svg>
            </div>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>
  </div>
</template>
<script>
import Login from '@/gql/Login.gql';

export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
    };
  },
  computed: {
    formValid() {
      return this.email && this.password;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      const { data } = await this.$apollo.query({
        query: Login,
        variables: {
          email: this.email,
        },
      });
      this.loading = false;
      const user = data.users[0];
      if (!user) {
        const registerNow = await this.$bvModal.msgBoxConfirm('You haven\'t signed up with this email address before. Would you like to register now?');
        if (registerNow) {
          this.$router.push({ name: 'Register' });
        }
      } else if (user.password !== this.password) {
        this.$bvModal.msgBoxOk('Incorrect password. Try again.');
      } else {
        localStorage.user_id = data.users[0].id;
        this.$router.push({ name: 'Dashboard' });
      }
      this.loading = false;
    },
  },
};
</script>
