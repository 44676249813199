<template>
  <div>
    <section>
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center no-gutters min-vh-100">
          <div class="col-12 col-md-6 col-lg-4 py-8 py-md-11">

            <!-- Heading -->
            <h1 class="mb-0 font-weight-bold">
              Sign up
            </h1>

            <!-- Text -->
            <p class="mb-6 text-muted">
              Cut your cloud costs in minutes.
            </p>

            <!-- Form -->
            <form class="mb-6" @submit.prevent="register()">

              <!-- Name -->
              <div class="form-group">
                <label for="name">
                  Name
                </label>
                <input type="text" class="form-control" id="name" placeholder="Enter your name" v-model="name">
              </div>

              <!-- Email -->
              <div class="form-group">
                <label for="email">
                  Email Address
                </label>
                <input type="email" class="form-control" id="email" placeholder="name@address.com" v-model="email">
              </div>

              <!-- Password -->
              <div class="form-group mb-5">
                <label for="password">
                  Password
                </label>
                <input type="password" class="form-control" id="password" placeholder="Enter your password" v-model="password">
              </div>

              <!-- Agreement -->
              <b-form-checkbox class="mb-5" v-model="accept_terms">I accept the terms of use</b-form-checkbox>

              <!-- Submit -->
              <button class="btn btn-block btn-primary" type="submit"
                :disabled="!formValid"
                v-if="!loading"
              >
                Sign up
              </button>
              <b-spinner label="Loading" v-if="loading"></b-spinner>

            </form>

            <!-- Text -->
            <p class="mb-0 font-size-sm text-muted">
              Already have an account? <a href="#/login">Log in</a>.
            </p>

          </div>
          <div class="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">

            <!-- Image -->
            <div class="h-100 w-cover bg-cover" style="background-image: url(assets/img/covers/cover-15.jpg);"></div>

            <!-- Shape -->
            <div class="shape shape-left shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"/></svg>
            </div>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>
  </div>
</template>
<script>
import RegisterUser from '@/gql/RegisterUser.gql';

export default {
  data() {
    return {
      name: null,
      email: null,
      password: null,
      accept_terms: null,
      loading: false,
    };
  },
  computed: {
    formValid() {
      return this.name && this.email && this.password && this.accept_terms;
    },
  },
  methods: {
    async register() {
      console.log('A');
      if (!this.formValid) {
        return false;
      }
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: RegisterUser,
          variables: {
            name: this.name,
            email: this.email,
            password: this.password,
            accepted_terms_at: new Date(),
          },
        });
        localStorage.user_id = data.user.id;
        console.log(localStorage.user_id);
        this.loading = false;
        this.$router.push({ name: 'Dashboard' });
      } catch (err) {
        if (err.message && err.message.includes('user_email_key')) {
          const loginNow = await this.$bvModal.msgBoxConfirm('An account with this email already exists. Would you like to login?');
          if (loginNow) {
            this.$router.push({ name: 'Login' });
          }
        } else {
          await this.$bvModal.msgBoxOk('An unknown error occurred. Please contact support.');
        }
        this.loading = false;
      }
      return true;
    },
  },
};
</script>
