<template>
  <div class="container">
    <app-feature-set :set="set" v-for="set in sets" :key="set.title"></app-feature-set>
  </div>
</template>
<script>
import AppFeatureSet from '@/components/features/FeatureSet.vue';

export default {
  components: {
    AppFeatureSet,
  },
  data() {
    return {
      sets: [
        {
          tag: 'Cloud',
          title: 'Core Cloud Features',
          description: 'Our cost-effective core cloud features enable your dev team to build cheaper and faster than ever.',
          features: [
            {
              title: 'API Cloud',
              description: 'Run APIs and other services in our low-cost container cloud',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/api.png',
            },
            {
              title: 'Data Cloud',
              description: 'Query petabytes of structured data in our managed SQL and No-SQL solutions',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/database.png',
            },
            {
              title: 'File Cloud',
              description: 'Store exabytes in our globally-replicated, multi-cloud storage buckets',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/file-submodule.png',
            },
            {
              title: 'CDN',
              description: 'Serve files cheaper and faster with our multi-cloud CDN',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/download-from-cloud--v1.png.png',
            },
            {
              title: 'Cron',
              description: 'Run scheduled processes with our global Cron service and Container Cloud',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/timer.png',
            },
            {
              title: 'Queue',
              description: 'Implement microservice architectures with Queue',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/descending-sorting.png',
            },
          ],
        },
        {
          tag: 'Media',
          title: 'Media Features',
          description: 'Our Media APIs help you delight your users with media',
          features: [
            {
              title: 'Image API',
              description: 'Resize, optimize, and transform images on demand to save bandwidth and speed user experience',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/edit-image.png',
            },
            {
              title: 'Video API',
              description: 'Generate searchable transcripts and dynamically convert any video into the right quality and format for every user',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/video-trimming.png',
            },
            {
              title: 'Audio API',
              description: 'Generate searchable transcripts and dynamically convert any audio file into the right quality and format for every user',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/audio-wave.png',
            },
          ],
        },
        {
          tag: 'Real-Time',
          title: 'Real-Time APIs',
          description: 'Our Real-Time APIs make it easy to affordably build interactive experiences',
          features: [
            {
              title: 'Presence API',
              description: 'Detect and share user presence easily',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/edit-image.png',
            },
            {
              title: 'Rooms API',
              description: 'Allow users to connect in powerful rooms, with chat, video, audio, and other features',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/living-room.png',
            },
            {
              title: 'P2P API',
              description: 'Allow users to share files directly with each other.',
              icon: 'https://img.icons8.com/ultraviolet/48/000000/torrent.png',
            },
          ],
        },
      ],
    };
  },
};
</script>
