<template>
  <div>
    <section class="pt-4 pt-md-11">
      <div class="container">
        <div class="row align-items-center">
          <!-- <div class="col-12 col-md-5 order-md-2">
            <img src="assets/img/illustrations/illustration-1.png" class="img-fluid mb-6 mb-md-0" alt="...">
          </div> -->
          <div class="col-12 col-md-7 order-md-1">
            <h1 class="display-4">
              <span class="text-primary">How we do it</span>
            </h1>
            <p class="lead text-muted mb-4">
              Backend.org uses 25 strategies to help our clients save on the cloud. Here are some of the secrets:
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-8 pt-md-11 pb-8 pb-md-11">
      <div class="container">
        <!-- <div class="row">
          <div class="col-12">
            <h2 class="mb-0">
              Horizontal articles
            </h2>
            <p class="mb-5 text-muted">
              These span the grid's entire width with several options.
            </p>
          </div>
        </div> -->
        <div class="row">
          <div class="card-columns">
            <router-link class="card mb-6 mb-lg-6 shadow-light-lg lift lift-lg" v-for="reason in reasons" :key="reason.id"
              :to="{name:'PerformanceDetail', params:reason}"
            >
              <div class="card-img-top">
                <img src="assets/img/photos/photo-15.jpg" alt="..." class="card-img-top">
                <div class="position-relative">
                  <div class="shape shape-bottom shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor"></path></svg>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h3>
                  {{reason.title}}
                </h3>
                <p class="mb-0 text-muted">
                  {{reason.description}}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div> <!-- / .container -->
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reasons: [
        {
          id: 'stablespot',
          title: '#1 Stable Spot',
          description: 'Stable Spot combines volatile spot instances to deliver high reliability at low cost.',
        },
        {
          id: 'followthemoon',
          title: '#2 Follow the Moon',
          description: 'Follow the Moon moves background work around the world to exploit off-peak cloud prices.',
        },
        {
          id: 'sharedreserve',
          title: '#3 Shared Reserve',
          description: 'Shared Reserve enables our clients to benefit from commited-use discounts with zero commitment.',
        },
        {
          id: 'shareddiscount',
          title: '#4 Market Clout',
          description: 'Market Clout combines demand from multiple clients to negotiate better prices for everyone.',
        },
        {
          id: 'popmesh',
          title: '#5 POP Mesh',
          description: 'POP Mesh deploys content to multiple clouds, and routes end users to the best cloud provider for their needs.',
        },
        {
          id: 'managedservices',
          title: '#6 Managed Services',
          description: 'Helm Services deliver high performance managed services at a fraction of the cost.',
        },
        {
          id: 'mcstandby',
          title: '#7 Multi-Cloud',
          description: 'Multi-Cloud deploys your service across multiple clouds, reducing cloud-specific risk',
        },
      ],
    };
  },
};
</script>
