<template>
  <div>
    <section class="pt-4 pt-md-11 bg-gray-200">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
              <!-- Heading -->
              <h2 class="font-weight-bold">
                <span class="text-primary">Powerful Performance</span>
              </h2>

              <!-- Text -->
              <p class="font-size-lg text-muted mb-7 mb-md-9">
                Architect uses 22 strategies to maximize the performance of your cloud.
                <br>
                Here are some of our secrets:
              </p>

          </div>
        </div>
        <div class="row">
          <div class="card-columns">
            <router-link class="card mb-6 mb-lg-6 shadow-light-lg lift lift-lg" v-for="reason in reasons" :key="reason.id"
              :to="{name:'PerformanceDetail', params:reason}"
            >
              <div class="card-img-top">
                <img :src="reason.picture" alt="..." class="card-img-top">
                <div class="position-relative">
                  <div class="shape shape-bottom shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor"></path></svg>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h3>
                  {{reason.title}}
                </h3>
                <p class="mb-0 text-muted">
                  {{reason.description}}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div> <!-- / .container -->
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reasons: [
        {
          id: 'stablespot',
          title: '#1 Stable Spot',
          picture: 'assets/img/strategies/strat1.jpg',
          description: 'Stable Spot combines volatile spot instances to deliver high reliability at low cost.',
        },
        {
          id: 'followthemoon',
          title: '#2 Follow the Moon',
          picture: 'assets/img/strategies/strat2.jpg',
          description: 'Follow the Moon moves background work around the world to exploit off-peak cloud prices.',
        },
        {
          id: 'sharedreserve',
          title: '#3 Shared Reserve',
          picture: 'assets/img/strategies/strat3.jpg',
          description: 'Shared Reserve enables our clients to benefit from commited-use discounts with zero commitment.',
        },
        {
          id: 'shareddiscount',
          title: '#4 Market Clout',
          picture: 'assets/img/strategies/strat4.jpg',
          description: 'Market Clout combines demand from multiple clients to negotiate better prices for everyone.',
        },
        {
          id: 'popmesh',
          title: '#5 POP Mesh',
          picture: 'assets/img/strategies/strat5.jpg',
          description: 'POP Mesh deploys content to multiple clouds, and routes end users to the best cloud provider for their needs.',
        },
        {
          id: 'managedservices',
          title: '#6 Helm Services',
          picture: 'assets/img/strategies/strat6.jpg',
          description: 'Helm Services deliver high performance managed services at a fraction of the cost.',
        },
      ],
    };
  },
};
</script>
