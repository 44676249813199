<template>
  <div>
    <app-jumbotron-section></app-jumbotron-section>
    <app-cloud-section></app-cloud-section>
    <app-strategies-section></app-strategies-section>
    <!-- <app-group-section></app-group-section>
    <app-experience-section></app-experience-section> -->
    <app-stats-section></app-stats-section>
    <!-- <app-services-section></app-services-section> -->
    <!-- <app-testimonial-section></app-testimonial-section> -->
    <app-workloads-section></app-workloads-section>
    <!-- <app-pricing-section></app-pricing-section> -->
    <app-response-section></app-response-section>
  </div>
</template>

<script>
import AppJumbotronSection from '@/components/home/JumbotronSection.vue';
// import AppServicesSection from '@/components/home/ServicesSection.vue';
import AppCloudSection from '@/components/home/CloudSection.vue';
// import AppExperienceSection from '@/components/home/ExperienceSection.vue';
import AppStatsSection from '@/components/home/StatsSection.vue';
// import AppTestimonialSection from '@/components/home/TestimonialSection.vue';
import AppResponseSection from '@/components/home/ResponseSection.vue';
// import AppGroupSection from '@/components/home/GroupSection.vue';
import AppStrategiesSection from '@/components/home/Strategies.vue';
import AppWorkloadsSection from '@/components/home/Workloads.vue';
// import AppPricingSection from '@/components/home/PricingSection.vue';

export default {
  name: 'Home',
  components: {
    AppJumbotronSection,
    // AppServicesSection,
    AppCloudSection,
    AppStatsSection,
    // AppExperienceSection,
    // AppTestimonialSection,
    AppResponseSection,
    // AppGroupSection,
    AppStrategiesSection,
    AppWorkloadsSection,
    // AppPricingSection,
  },
};
</script>
