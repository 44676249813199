<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col col-md-9 pb-5 less-margin">
          <vue-simple-markdown :source="source"></vue-simple-markdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      source: 'Loading...',
    };
  },
  async created() {
    const raw = await fetch(`/contracts/${this.id}.md`).then((r) => r.text());
    this.source = raw;
  },
};
</script>
<style>
  .less-margin h3, .less-margin h4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
</style>
