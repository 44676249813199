<template>
  <section class="pt-8 pt-md-11">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">

            <!-- Badge -->
            <span class="badge badge-pill badge-success-soft mb-3">
              <span class="h6 text-uppercase">{{set.tag}}</span>
            </span>

            <!-- Heading -->
            <h2 class="font-weight-bold">
              {{set.title}}
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-gray-700 mb-9">
              {{set.description}}
            </p>

          </div>
        </div> <!-- / .row -->
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 text-center aos-init aos-animate" data-aos="fade-up"
            v-for="feature in set.features" :key="feature.title">

            <!-- Icon -->
            <div class="icon icon-lg mb-4" v-if="feature.icon">
              <img :src="feature.icon" alt="">
            </div>

            <!-- Heading -->
            <h3 class="font-weight-bold">
              {{feature.title}}
            </h3>

            <!-- Text -->
            <p class="text-muted mb-8">
              {{feature.description}}
            </p>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>
</template>
<script>
export default {
  props: {
    set: {
      type: Object,
      required: true,
    },
  },
};
</script>
