<template>
  <b-navbar toggleable="lg" type="light" class="bg-white">
    <b-container>
      <b-navbar-brand :to="{name:'Home'}">Architect</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item :to="{name:'Performance'}">Strategies</b-nav-item>
          <b-nav-item :to="{name:'Features'}">Services</b-nav-item> -->
          <!-- <b-nav-item :to="{name:'About'}">Who we are</b-nav-item> -->
          <b-nav-form>
            <b-button href="https://csuitecircle.typeform.com/to/OiGKGRM7" target="_blank" variant="primary" size="sm">
              Learn More
              <i class="fe fe-arrow-right d-none d-md-inline ml-3"></i>
             </b-button>
            <!-- <b-button v-if="!loggedIn" :to="{name:'Register'}" variant="primary" size="sm">Sign Up</b-button>
            <b-button v-if="loggedIn" :to="{name:'Dashboard'}" variant="primary" size="sm">My Account</b-button> -->
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>
<script>
export default {
  computed: {
    loggedIn() {
      return !!localStorage.user_id;
    },
  },
};
</script>
