<template>
  <section class="py-6 py-md-8 border-bottom">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">

          <!-- <h2 class="font-weight-bold">
            <span class="text-primary">Multi-cloud</span> from the ground up.
          </h2> -->
          <p class="font-size-lg text-muted mb-4">
            Works with:
          </p>

        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0" v-for="cloud in clouds" :key="cloud">

          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0 svg-shim">
            <img :src="cloud" class="cloud-img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      clouds: [
        '/assets/img/clouds/aws.png',
        '/assets/img/clouds/gcp.png',
        '/assets/img/clouds/azure.png',
        '/assets/img/clouds/ibm.png',
        '/assets/img/clouds/oracle.jpeg',
        '/assets/img/clouds/alibaba.png',
      ],
    };
  },
};
</script>
<style scoped>
  .cloud-img
  {
    filter: grayscale(100%);
  }
  .cloud-img:hover
  {
    filter: grayscale(0%);
  }
</style>
