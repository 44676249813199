<template>
  <section class="pt-8 pt-md-11 bg-gray-200">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">

          <!-- Badge -->
          <span class="badge badge-pill badge-success-soft mb-3">
            <span class="h6 text-uppercase">Services</span>
          </span>

          <!-- Heading -->
          <h2 class="font-weight-bold">
            Works your way.
          </h2>

          <!-- Text -->
          <p class="font-size-lg text-gray-700 mb-9">
            Architect supports over 100 popular services to give you peace of mind.
          </p>

        </div>
      </div> <!-- / .row -->
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 text-center aos-init aos-animate" data-aos="fade-up" v-for="service in services" :key="service.id">

          <!-- Icon -->
          <div class="icon icon-lg mb-4">
            <div class="service-icon">
              <img :src="service.picture" alt="">
            </div>
          </div>

          <!-- Heading -->
          <h3 class="font-weight-bold">{{service.name}}</h3>

          <!-- Text -->
          <p class="text-muted mb-8 service-description">{{service.description}}</p>

        </div>
      </div> <!-- / .row -->
    </div> <!-- / .container -->
  </section>
</template>
<script>
export default {
  data() {
    return {
      services: [
        {
          id: 'postgres',
          name: 'Postgresql',
          picture: 'https://artifacthub.io/image/2a4da4ce-b519-46af-bb1c-d845e5d9a772@2x',
          description: 'Chart for PostgreSQL, an object-relational database management system (ORDBMS) with an emphasis on extensibility and on standards-compliance.',
        },
        {
          id: 'redis',
          name: 'Redis',
          picture: 'https://artifacthub.io/image/580dd261-30c5-4537-ab19-290a49fb8e51@2x',
          description: 'Open source, advanced key-value store. It is often referred to as a data structure server since keys can contain strings, hashes, lists, sets and sorted sets.',
        },
        {
          id: 'elasticsearch',
          name: 'ElasticSearch',
          picture: 'https://artifacthub.io/image/d42a8425-4846-4a70-8895-b2845ee6241b@2x',
          description: 'A highly scalable open-source full-text search and analytics engine.  It allows you to store, search, and analyze big volumes of data quickly and in near real time.',
        },
        {
          id: 'prometheus',
          name: 'Prometheus',
          picture: 'https://artifacthub.io/image/0503add5-3fce-4b63-bbf3-b9f649512a86@2x',
          description: 'Prometheus is a monitoring system and time series database.',
        },
        {
          id: 'jenkins',
          name: 'Jenkins',
          picture: 'https://artifacthub.io/image/7a83fbc7-58a6-42d9-876f-75608f8f5679@2x',
          description: 'Jenkins - Build great things at any scale! The leading open source automation server, Jenkins provides hundreds of plugins to support building, deploying and automating any project.',
        },
        {
          id: 'mongodb',
          name: 'MongoDB',
          picture: 'https://artifacthub.io/image/7aa9752b-4857-4485-a9de-d5dc9e35ed21@2x',
          description: 'NoSQL document-oriented database that stores JSON-like documents with dynamic schemas, simplifying the integration of data in content-driven applications.',
        },
      ],
    };
  },
};
</script>
<style scoped>
.service-icon
{
  width: 100px;
  height: 100px;
  background-color: white;
  border: solid 1px #ccc;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.service-icon img
{
  max-width: 80px;
  max-height: 80px;
}
.service-description
{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
