import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

function getHeaders() {
  return {
    'x-hasura-admin-secret': 'fallacious-salt',
  };
}
const wsLink = new WebSocketLink({
  uri: 'wss://gcr-f2a80e21-b82b-4496-9684-f2744ed97a0f-5qvrslo3za-uc.a.run.app/v1/graphql',
  options: {
    reconnect: true,
    connectionParams: {
      headers: getHeaders(),
    },
  },
});

const httpLink = new HttpLink({
  uri: 'https://gcr-f2a80e21-b82b-4496-9684-f2744ed97a0f-5qvrslo3za-uc.a.run.app/v1/graphql',
  headers: getHeaders(),
});

// split based on operation type
const link = split(({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === 'OperationDefinition' && operation === 'subscription';
}, wsLink, httpLink);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
